import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { AbstractInput } from '../abstract/input';

export const PERCENT_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputPercentComponent),
	multi: true
};

export const PERCENT_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputPercentComponent),
	multi: true,
};

@Component({
	selector: 'app-input-percent',
	templateUrl: 'percent.component.html',
	providers: [PERCENT_VALUE_ACCESSOR, PERCENT_VALUE_VALIDATOR]
})
export class InputPercentComponent extends AbstractInput<number> {
	get inner(): string {
		if (this.value || this.value == 0) {
			return this.asNumber(this.value).toString();
		}

		return null;
	}

	set inner(value: string) {
		this.value = this.asNumber(value);

		if (this.min != null && this.value < this.min) {
			this.value = this.min;
		}

		if (this.max != null && this.value > this.max) {
			this.value = this.max;
		}

		this.writeValue(this.value);
	}

	@Input()
	placeholder: string = '%';

	@Input()
	min: number = 0;

	@Input()
	max: number = 100;

	validate(control: FormControl): ValidationErrors | null {
		if (this.min !== null && control.value < this.min) {
			return {
				min: this.min
			};
		}

		if (this.max !== null && control.value > this.max) {
			return {
				max: this.max
			};
		}

		return null;
	}

	getMoneyMask() {
		return {
			mask: createNumberMask({
				prefix: '',
				suffix: '%',
				includeThousandsSeparator: true,
				allowDecimal: true,
				decimalLimit: 3,
				integerLimit: this.max ? this.max.toString().length : null,
				requireDecimal: false,
				allowNegative: this.min != null ? this.min < 0 : true
			})
		};
	}

	private asNumber(value: string | number): number {
		if (!value) {
			return null;
		}

		if (typeof value === 'string') {
			value = value.replace(/[^\d]/g, '');
		}

		return Number(value);
	}
}
