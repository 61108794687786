import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'alert.component.html'
})
export class AlertComponent {
	model: ModalOptions;

	heading: SafeHtml;
	message: SafeHtml;

	constructor(
		private modalRef: BsModalRef,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		if (this.model.heading) {
			this.heading = this.sanitizer.bypassSecurityTrustHtml(this.model.heading);
		}

		if (this.model.message) {
			this.message = this.sanitizer.bypassSecurityTrustHtml(this.model.message);
		}

		this.model.submit = Object.assign({
			text: 'OK',
			type: 'primary'
		}, this.model.submit);
	}

	onSubmit() {
		this.modalRef.hide();
	}
}
