<form #form="ngForm" (input)="onValidate()" (submit)="onSubmit()" novalidate>
	<div class="modal-header">
		<h5 class="modal-title" [innerHTML]="model?.heading"></h5>

		<button type="button" class="close" (click)="onCancel()" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<div class="form-group">
			<label for="prompt-input" [innerHTML]="model?.message"></label>
			<input type="text" class="form-control" id="prompt-input" name="input" [(ngModel)]="input" [required]="true" [textMask]="this.model.input?.textMask || { mask: false }" [placeholder]="this.model.input?.placeholder || ''" />

			<ng-container *ngIf="errors">
				<div class="form-text text-danger" *ngFor="let error of errors | keyvalue">
					<div>{{ error.value }}</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn" [ngClass]="'btn-' + (model.cancel?.type || 'link')" (click)="onCancel()">
			{{ model.cancel?.text || 'Cancel' }}
		</button>

		<button type="submit" class="btn" [ngClass]="'btn-' + (model.submit?.type || 'primary')" [disabled]="form.invalid || errors">
			{{ model.submit?.text || 'Submit' }}
		</button>
	</div>
</form>
