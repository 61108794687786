import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'confirm.component.html'
})
export class ConfirmComponent implements OnInit {
	model: ModalOptions;

	heading: SafeHtml;
	message: SafeHtml;

	result: boolean = false;

	constructor(
		private modalRef: BsModalRef,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		if (this.model.heading) {
			this.heading = this.sanitizer.bypassSecurityTrustHtml(this.model.heading);
		}

		if (this.model.message) {
			this.message = this.sanitizer.bypassSecurityTrustHtml(this.model.message);
		}

		this.model.submit = Object.assign({
			text: 'Submit',
			type: 'primary'
		}, this.model.submit);

		this.model.cancel = Object.assign({
			text: 'Cancel',
			type: 'link'
		}, this.model.cancel);
	}

	onSubmit() {
		this.result = true;
		this.modalRef.hide();
	}

	onCancel() {
		this.result = false;
		this.modalRef.hide();
	}
}
