import { Component, OnInit } from '@angular/core';

import { AuthService } from '@myapp/core/services';

import { environment } from '@myapp/environment';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit {
	isAuthenticated: boolean = false;
	showSignIn: boolean = !environment.production;

	constructor(
		private authService: AuthService
	) {
	}

	ngOnInit() {
		this.authService.changes().subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
		});
	}
}
