import { Component, Input, ContentChild, TemplateRef } from '@angular/core';

import { DataColumnHeadingComponent } from './data-column-heading.component';

@Component({
	selector: 'app-data-column',
	templateUrl: 'data-column.component.html'
})
export class DataColumnComponent {
	@Input()
	public index: string;

	@Input()
	public heading: string;

	@ContentChild(DataColumnHeadingComponent, { static: false })
	public headingTemplate: DataColumnHeadingComponent;

	@Input()
	public sort: string;

	@Input()
	public width: string = 'auto';

	@Input()
	public align: 'left' | 'right' | 'center' = 'left';

	@Input()
	public background: string = 'transparent';

	@Input()
	public class: string = '';

	@ContentChild(TemplateRef, { static: false })
	public template: TemplateRef<{}>;
}
