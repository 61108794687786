import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const TEXTAREA_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputTextAreaComponent),
	multi: true
};

export const TEXTAREA_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputTextAreaComponent),
	multi: true,
};

@Component({
	selector: 'app-input-textarea',
	templateUrl: 'textarea.component.html',
	providers: [TEXTAREA_VALUE_ACCESSOR, TEXTAREA_VALUE_VALIDATOR]
})
export class InputTextAreaComponent extends AbstractInput<string> {
	@Input()
	rows: number = 2;
}
