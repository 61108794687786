<div class="form-group row align-items-center">
	<label class="col-form-label text-right" [ngClass]="labelClass" [class.font-weight-bold]="strongOn !== null && value === strongOn">
		<ng-container *ngIf="label">
			<span [innerHTML]="label"></span>
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div class="align-self-center" [ngClass]="inputClass">
		<div class="custom-control custom-radio custom-control-inline">
			<input type="radio" class="custom-control-input" [id]="id + 'Yes'" [name]="name" [(ngModel)]="value" [value]="true" [required]="required" [disabled]="disabled || readonly" />
			<label class="custom-control-label" [for]="id + 'Yes'">Yes</label>
		</div>

		<div class="custom-control custom-radio custom-control-inline" [class.is-invalid]="!isValid">
			<input type="radio" class="custom-control-input" [id]="id + 'No'" [name]="name" [(ngModel)]="value" [value]="false" [required]="required" [disabled]="disabled || readonly" />
			<label class="custom-control-label" [for]="id + 'No'">No</label>
		</div>
	</div>
</div>
