export const environment = {
	production: false,

	pwa: {
		enabled: true
	},

	rest: {
		apiHost: 'https://mdpt-api-dev.azurewebsites.net'
	},

	authentication: {
		authority: 'https://mdpt-auth-dev.azurewebsites.net',

		cors: [
			'mdpt-api-dev.azurewebsites.net',
			'mdpt-auth-dev.azurewebsites.net'
		],

		authorize: {
			endPoint: '/connect/token',
			tokenKeyName: 'access_token',
			clientID: 'myapp',
			grantType: 'password',
			scope: 'openid profile offline_access myapp'
		},

		refresh: {
			endPoint: '/connect/token',
			tokenKeyName: 'refresh_token'
		}
	},

	applicationInsights: {
		instrumentationKey: 'f3611ef8-0c7e-40c1-9295-c2c487c5a92e'
	},

	captcha: {
		siteKey: '6LeGPLgZAAAAAO0rPzGA29WY8UcsuYwla037X-C9'
	},

	maps: {
		apiKey: 'AIzaSyC4loV7l4raULluJAzr9M_HphaPFwedHxY'
	},

	inactivity: {
		idle: 1800,
		timeout: 300,
		ping: 120
	}
};

export enum PermissionType {
	Home_View = 1,
	Maintenance_View = 2
}
