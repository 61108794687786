<header ckass="header">
	<nav class="navbar navbar-expand-lg navbar-light border-bottom">
		<div class="container my-2">
			<a class="navbar-brand mx-auto mx-sm-0" routerLink="/">
				<img height="40" src="/assets/logos/mdpt-logo-horizontal.svg" alt="MDPT Connect Logo" />
			</a>

			<div class="collapse navbar-collapse">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/">Home</a>
					</li>
					<ng-container *ngIf="showSignIn">
						<ng-container *ngIf="!isAuthenticated">
							<li class="nav-item" routerLinkActive="active">
								<a class="nav-link" routerLink="/account/sign-in">Sign in</a>
							</li>
						</ng-container>

						<ng-container *ngIf="isAuthenticated">
							<li class="nav-item" routerLinkActive="active">
								<a class="nav-link" routerLink="/maintenance">Maintenance</a>
							</li>

							<li class="nav-item" routerLinkActive="active">
								<a class="nav-link" routerLink="/account/sign-out">Sign out</a>
							</li>
						</ng-container>
					</ng-container>
				</ul>
			</div>
		</div>
	</nav>
</header>
