<footer>
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<div class="container my-5">
			<a class="navbar-brand mx-auto mx-sm-0" routerLink="/">
				<img height="50" src="/assets/logos/mdpt-logo-horizontal.svg" alt="MDPT Connect Logo" />
			</a>

			<div class="collapse navbar-collapse">
				<ul class="navbar-nav align-items-center ml-auto">
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/terms">
							Terms
						</a>
					</li>					
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/privacy">
							Privacy
						</a>
					</li>					
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" href="mailto:hello@mdptconnect.com">
							hello@mdptconnect.com
						</a>
					</li>					
				</ul>
			</div>
		</div>
	</nav>
</footer>
