import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
	path: 'account',
	loadChildren: () => import('./modules/account/module').then(m => m.AccountModule)
}, {
	path: 'maintenance',
	loadChildren: () => import('./modules/maintenance/module').then(m => m.MaintenanceModule)
}, {
	path: 'profile',
	loadChildren: () => import('./modules/profile/module').then(m => m.ProfileModule)
}, {
	path: '',
	loadChildren: () => import('./modules/home/module').then(m => m.HomeModule)
}, {
	path: '**',
	redirectTo: ''
}];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
