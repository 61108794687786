import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TextMaskModule } from 'angular2-text-mask';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { AgmCoreModule } from '@agm/core';
import { AreaChartModule } from '@swimlane/ngx-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

import { environment } from '@myapp/environment';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';

@NgModule({
	declarations: [
		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,

		FontAwesomeModule,
		TextMaskModule,
		AgmCoreModule.forRoot(environment.maps),
		AreaChartModule,
		InfiniteScrollModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,

		FontAwesomeModule,
		TextMaskModule,
		AgmCoreModule,
		AreaChartModule,
		InfiniteScrollModule,

		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	entryComponents: [
		COMPONENTS
	],
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fal);
	}
}
