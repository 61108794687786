<div class="form-group row">
	<label class="col-form-label text-right" [ngClass]="labelClass" [for]="id">
		<ng-container *ngIf="label">
			<span [innerHTML]="label"></span>
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div class="align-self-center" [ngClass]="inputClass">
		<input type="text" class="form-control"
			   [id]="id"
			   [name]="name"
			   [(ngModel)]="value"
			   [placeholder]="placeholder"
			   [required]="required"
			   [readonly]="readonly"
			   [disabled]="disabled"
			   [textMask]="mask"
			   [class.is-invalid]="!isValid" />

		<div *ngIf="hint" class="form-text text-muted" [innerHTML]="hint"></div>
	</div>
</div>
