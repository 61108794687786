import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
	public transform(array: Array<any>, args: string): Array<string> | Array<any> {
		let properties = args.split(',')
			.map(p => p.trim());

		for (let property of properties) {
			let reverse = property.charAt(0) === '-';

			property = property
				.replace('-', '')
				.replace('+', '');

			array.sort((a: any, b: any) => {
				if (a[property] === b[property]) {
					return 0;
				}

				if (a[property] > b[property]) {
					return reverse ? -1 : 1;
				}

				return reverse ? 1 : -1;
			});
		}

		return array;
	}
}
