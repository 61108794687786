import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { AbstractInput } from '../abstract/input';

export const NUMBER_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputNumberComponent),
	multi: true
};

export const NUMBER_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputNumberComponent),
	multi: true,
};

@Component({
	selector: 'app-input-number',
	templateUrl: 'number.component.html',
	providers: [NUMBER_VALUE_ACCESSOR, NUMBER_VALUE_VALIDATOR]
})
export class InputNumberComponent extends AbstractInput<number> {
	private _formatThousands = true;

	get inner(): string {
		if (this.value || this.value == 0) {
			return this.asNumber(this.value).toString();
		}

		return null;
	}

	set inner(value: string) {
		this.value = this.asNumber(value);

		if (this.min != null && this.value < this.min) {
			this.value = this.min;
		}

		if (this.max != null && this.value > this.max) {
			this.value = this.max;
		}

		this.writeValue(this.value);
	}

	@Input()
	get formatThousands() {
		return this._formatThousands;
	}
	set formatThousands(value: boolean) {
		if (this._formatThousands === value) {
			return;
		}

		this._formatThousands = value;
		this.moneyMask = this.getMoneyMask();
	}

	moneyMask = this.getMoneyMask();

	@Input()
	placeholder: string = '#';

	@Input()
	min: number = null;

	@Input()
	max: number = null;

	validate(control: FormControl): ValidationErrors | null {
		if (this.min != null && control.value < this.min) {
			return {
				min: this.min
			};
		}

		if (this.max != null && control.value > this.max) {
			return {
				max: this.max
			};
		}

		return null;
	}

	private getMoneyMask() {
		return {
			mask: createNumberMask({
				prefix: '',
				suffix: '',
				includeThousandsSeparator: this.formatThousands,
				allowNegative: this.min != null ? this.min < 0 : true
			})
		};
	}

	private asNumber(value: string | number): number {
		if (!value) {
			return null;
		}

		if (typeof value === 'string') {
			value = value.replace(/[^\d]/g, '');
		}

		return Number(value);
	}
}
