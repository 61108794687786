import { Component, AfterContentInit, Input, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';

import { DataColumnComponent } from './data-column.component';

interface DataTableSelectOptions {
	enabled?: boolean;
	property?: string;
	filter?: (entry: any) => boolean;
}

@Component({
	selector: 'app-data-table',
	templateUrl: 'data-table.component.html'
})
export class DataTableComponent implements AfterContentInit {
	@Input()
	size: string = 'md';

	@Input()
	striped: boolean = false;

	@Input()
	data: Array<any> = [];

	@Input()
	empty: string = '';

	@Input()
	sortedBy: string = '';

	@Input()
	groupBy: string = '';

	// TODO: This should be configured in HTML or in main.css (line 119),
	// so we have consistent table styles everywhere
	@Input()
	headClass: string = 'thead-light';

	// TODO: "class" works like a proxy, pass class to head's and row's cell
	@Input()
	class: string = '';

	@ContentChildren(DataColumnComponent)
	columns: QueryList<DataColumnComponent>;

	@Input()
	selection: Array<any> = [];

	@Input()
	selectionOptions: DataTableSelectOptions = { enabled: false };

	@Output()
	selectionChange: EventEmitter<any[]> = new EventEmitter();

	ngAfterContentInit(): void {
	}

	getColumnStyles(column: DataColumnComponent): any {
		return {
			// 'background-color': column.background,
			'text-align': column.align,
			'width': column.width
		};
	}

	onSortBy(field: string): void {
		if (this.sortedBy.slice(1) == field) {
			this.sortedBy = (this.sortedBy[0] == '+' ? '-' : '+') + field;
		} else {
			this.sortedBy = '+' + field;
		}
	}

	onSelectAll() {
		if (this.selection) {
			if (this.isIndeterminate()) {
				for (let entry of this.data.filter(m => this.isCheckable(m))) {
					if (this.isSelected(entry) === false) {
						this.onSelect(entry);
					}
				}

				return;
			}

			for (let entry of this.data.filter(m => this.isCheckable(m))) {
				this.onSelect(entry);
			}
		}
	}

	isCheckedAll(): boolean {
		if (this.selection && this.selection.length > 0) {
			return this.selection.length === this.data.filter(m => this.isCheckable(m)).length;
		}

		return false;
	}

	isIndeterminate(): boolean {
		if (this.selection) {
			return this.selection.length > 0 && this.selection.length < this.data.filter(m => this.isCheckable(m)).length;
		}

		return false;
	}

	onSelect(entry: any) {
		if (this.selection) {
			let value = entry[this.selectionOptions.property];
			let index = this.selection.indexOf(value);

			if (index === -1 && this.isCheckable(entry)) {
				this.selection.push(value);
			} else {
				this.selection.splice(index, 1);
			}

			this.selectionChange.emit(this.selection);
		}
	}

	isSelected(entry: any): boolean {
		if (this.selection) {
			return this.isCheckable(entry) && this.selection.indexOf(entry[this.selectionOptions.property]) !== -1;
		}

		return false;
	}

	isCheckable(entry: any): boolean {
		if (this.selectionOptions.enabled && this.selectionOptions.filter) {
			return this.selectionOptions.filter(entry);
		}

		return true;
	}
}
