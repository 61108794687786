import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpParameterCodec } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';

import { PermissionType } from '@myapp/environment';

import { environment } from '@myapp/environment';

export function getAuthToken() {
	return sessionStorage.getItem(environment.authentication.authorize.tokenKeyName);
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private idleStart$ = null;
	private idleTimeout$ = null;
	private idleEnd$ = null;

	private idleToastrID = null;

	private permissionsClaimName = 'Permissions';

	state = new BehaviorSubject(this.isAuthenticated);
	user = new BehaviorSubject(null);

	get accessTokenKeyName(): string {
		return environment.authentication.authorize.tokenKeyName;
	}

	get refreshTokenKeyName(): string {
		return environment.authentication.refresh.tokenKeyName;
	}

	get token(): any {
		return this.isAuthenticated
			? this.jwtHelper.decodeToken(this.jwtHelper.tokenGetter())
			: null;
	}

	get isAuthenticated(): boolean {
		let token = this.jwtHelper.tokenGetter();
		let authenticated = token && !this.jwtHelper.isTokenExpired(token);

		// notify subscribers if auth status changes outside of token management
		if (this.state && this.state.value !== authenticated) {
			this.state.next(authenticated);
			this.user.next(this.token);
		}

		return authenticated;
	}

	constructor(
		private http: HttpClient,
		private jwtHelper: JwtHelperService,
		private idle: Idle,
		private toastr: ToastrService,
		private router: Router
	) {
		if (this.isAuthenticated) {
			this.startIdleTimer();
		}
	}

	authorize(username: string, password: string) {
		let settings = environment.authentication.authorize;

		let params = new HttpParams({
			encoder: new HttpUrlEncodingCodec(),
			fromObject: {
				grant_type: settings.grantType,
				username: username,
				password: password,
				scope: settings.scope,
				client_id: settings.clientID
			}
		});

		let options = {
			headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
		};

		return this.http.post(environment.authentication.authority + settings.endPoint, params, options).pipe(
			tap((response) => {
				this.saveToken(response);
				this.startIdleTimer();
			})
		);
	}

	deauthorize() {
		this.deleteToken();
		this.stopIdleTimer();

		return of(null);
	}

	changes(): Observable<boolean> {
		return this.state.asObservable();
	}

	hasPermission(permissionTypes: PermissionType[]): boolean {
		if (this.isAuthenticated) {
			let permissions = this.token[this.permissionsClaimName];

			if (permissions) {
				for (let permissionType of permissionTypes) {
					if (permissions.indexOf(permissionType.toString()) !== -1) {
						return true;
					}
				}
			}
		}

		return false;
	}

	private saveToken(response): void {
		if (response && response.access_token) {
			sessionStorage.setItem(this.accessTokenKeyName, response.access_token);

			this.state.next(this.isAuthenticated);

			if (response.refresh_token) {
				sessionStorage.setItem(this.refreshTokenKeyName, response.refresh_token);
			}
		} else {
		}

		this.user.next(this.token);
	}

	private deleteToken(): void {
		sessionStorage.removeItem(environment.authentication.authorize.tokenKeyName);

		this.state.next(false);
		this.user.next(this.token);
	}

	private startIdleTimer(): void {
		this.idle.setIdle(environment.inactivity.idle);
		this.idle.setTimeout(environment.inactivity.timeout);
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		let toastrOptions = {
			disableTimeOut: true
		};

		if (this.idleToastrID) {
			this.toastr.clear(this.idleToastrID);
		}

		// Performs action when user is inactive for {environment.inactivity.idle} seconds
		this.idleStart$ = this.idle.onIdleStart.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}

			this.idleToastrID = this.toastr
				.warning(`You will be automatically logged out in a couple of minutes due to inactivity.`, null, toastrOptions)
				.toastId;
		});

		// Performs action when user is inactive for {environment.inactivity.idle + environment.inactivity.timeout} seconds
		this.idleTimeout$ = this.idle.onTimeout.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}

			this.idleToastrID = this.toastr
				.warning(`You were automatically logged out due to ${(environment.inactivity.idle / 60).toFixed(0)} minutes of inactivity.`, null, toastrOptions)
				.toastId;

			this.router.navigateByUrl('/account/sign-out');
		});

		// Performs action when user is back active
		this.idleEnd$ = this.idle.onIdleEnd.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}
		});

		this.idle.watch();
	}

	private stopIdleTimer(): void {
		this.idle.stop();

		if (this.idleStart$) {
			this.idleStart$.unsubscribe();
		}

		if (this.idleTimeout$) {
			this.idleTimeout$.unsubscribe();
		}

		if (this.idleEnd$) {
			this.idleEnd$.unsubscribe();
		}
	}
}

export class HttpUrlEncodingCodec implements HttpParameterCodec {
	encodeKey(k: string): string { return encodeURIComponent(k); }
	encodeValue(v: string): string { return encodeURIComponent(v); }
	decodeKey(k: string): string { return decodeURIComponent(k); }
	decodeValue(v: string) { return decodeURIComponent(v); }
}
