import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { AbstractInput } from '../abstract/input';

export const MONEY_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputMoneyComponent),
	multi: true
};

export const MONEY_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputMoneyComponent),
	multi: true,
};

@Component({
	selector: 'app-input-money',
	templateUrl: 'money.component.html',
	providers: [MONEY_VALUE_ACCESSOR, MONEY_VALUE_VALIDATOR]
})
export class InputMoneyComponent extends AbstractInput<number> {
	get moneyMask() {
		return {
			mask: createNumberMask({
				prefix: '$',
				includeThousandsSeparator: true,
				allowDecimal: true,
				decimalLimit: 2,
				requireDecimal: true,
				allowNegative: this.min != null ? this.min < 0 : true
			})
		};
	}

	get inner(): string {
		if (this.value || this.value == 0) {
			return this.asNumber(this.value).toString();
		}

		return null;
	}

	set inner(value: string) {
		this.value = this.asNumber(value);

		if (this.min != null && this.value < this.min) {
			this.value = this.min;
		}

		if (this.max != null && this.value > this.max) {
			this.value = this.max;
		}

		this.writeValue(this.value);
	}

	@Input()
	placeholder: string = '$';

	@Input()
	min: number;

	@Input()
	max: number;

	validate(control: FormControl): ValidationErrors | null {
		if (this.min != null && control.value < this.min) {
			return {
				min: this.min
			};
		}

		if (this.max != null && control.value > this.max) {
			return {
				max: this.max
			};
		}

		return null;
	}

	private asNumber(value: string | number): number {
		if (!value) {
			return null;
		}

		if (typeof value === 'string') {
			value = value.replace(/[^\d]/g, '');
		}

		return Number(value);
	}
}
