import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DatePipe } from '@angular/common';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { AbstractInput } from '../abstract/input';

export const DATE_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputDateComponent),
	multi: true
};

export const DATE_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputDateComponent),
	multi: true,
};

@Component({
	selector: 'app-input-date',
	templateUrl: 'date.component.html',
	providers: [DATE_VALUE_ACCESSOR, DATE_VALUE_VALIDATOR]
})
export class InputDateComponent extends AbstractInput<Date> {
	private datePipe: DatePipe = new DatePipe('en-US');

	dateMask = {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'),
		keepCharPositions: true
	};

	get inner(): string {
		if (this.value) {
			return this.datePipe.transform(this.value, this.format, 'UTC');
		}

		return '';
	}

	set inner(value: string) {
		let date = new Date(value);

		if (isNaN(date.getTime()) === false) {
			this.value = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
		} else {
			// TODO: Avoid setting null if date is invalid
			this.value = null;
		}

		this.writeValue(this.value);
	}

	@Input()
	format: string = 'MM/dd/yyyy';

	@Input()
	placeholder: string = 'mm/dd/yyyy';

	@Input()
	min: Date;

	@Input()
	max: Date;

	validate(control: FormControl): ValidationErrors | null {
		let value = new Date(control.value);

		if (value) {
			if (this.min) {
				if (typeof (this.min) === 'string') {
					this.min = new Date(this.min);
				}

				let minDate = new Date(Date.UTC(this.min.getUTCFullYear(), this.min.getUTCMonth(), this.min.getUTCDate()));

				if (value < minDate) {
					return {
						min: this.datePipe.transform(minDate, this.format, 'UTC')
					};
				}
			}

			if (this.max) {
				if (typeof (this.max) === 'string') {
					this.max = new Date(this.max);
				}

				let maxDate = new Date(Date.UTC(this.max.getUTCFullYear(), this.max.getUTCMonth(), this.max.getUTCDate()));

				if (value > maxDate) {
					return {
						max: this.datePipe.transform(maxDate, this.format, 'UTC')
					};
				}
			}
		}

		return null;
	}
}
