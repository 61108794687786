<div class="form-group row">
	<label class="col-form-label text-right" [ngClass]="labelClass" [for]="id">
		<ng-container *ngIf="label">
			<span [innerHTML]="label"></span>
		</ng-container>

		<ng-container *ngIf="!label">
			<ng-content></ng-content>
		</ng-container>
	</label>

	<div [ngClass]="inputClass">
		<select class="custom-select" [id]="id" [name]="name" [(ngModel)]="value" [required]="required" [disabled]="disabled || source?.length == 0" [class.is-invalid]="!isValid">
			<option [selected]="true" [ngValue]="null">
				{{ placeholder }}
			</option>

			<option *ngFor="let entry of source" [value]="entry[itemValue]">
				{{ entry[itemLabel] }}
			</option>
		</select>

		<div *ngIf="hint" class="form-text text-muted" [innerHTML]="hint"></div>
	</div>
</div>
