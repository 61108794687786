<div class="modal-header">
	<h5 class="modal-title" [innerHTML]="heading"></h5>

	<button type="button" class="close" (click)="onSubmit()" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="form-group" [innerHTML]="message">
	</div>
</div>

<div class="modal-footer">
	<button type="submit" class="btn" [ngClass]="'btn-' + model.submit.type" (click)="onSubmit()">
		{{ model.submit.text }}
	</button>
</div>
