import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { GUARDS } from './guards';

@NgModule({
	imports: [
		CommonModule,
		FormsModule
	],
	providers: [
		GUARDS
	]
})
export class CoreModule {
}
