import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { BsModalService } from 'ngx-bootstrap/modal';

import { UpdateComponent } from '@myapp/shared/components';

import * as version from '../version.json';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	private build: string = version.default.build;

	constructor(
		private modalService: BsModalService,
		private updates: SwUpdate
	) {
	}

	ngOnInit() {
		document.body.setAttribute('build', this.build);

		if (this.updates.isEnabled) {
			this.updates.checkForUpdate();

			this.updates.available.subscribe(event => {
				console.info(`PWA Update Available: ${event.current.hash} -> ${event.available.hash}`);

				this.modalService.show(UpdateComponent, {
					ignoreBackdropClick: true
				});
			});
		}
	}
}
