import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';

import { CoreModule } from '@myapp/core/module';
import { SharedModule } from '@myapp/shared/module';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { getAuthToken } from '@myapp/core/services/auth.service';

import { environment } from '@myapp/environment';

let appInsightsModules = [];
if (environment.applicationInsights.instrumentationKey) {
	appInsightsModules = [ApplicationInsightsModule.forRoot(environment.applicationInsights)];
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		...appInsightsModules,

		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,

		ServiceWorkerModule.register('ngsw-worker.js', environment.pwa),

		JwtModule.forRoot({
			config: {
				tokenGetter: getAuthToken,
				whitelistedDomains: environment.authentication.cors
			}
		}),

		ToastrModule.forRoot({
			toastClass: 'ngx-toastr app-toast',
			positionClass: 'toast-top-center',
			enableHtml: true,
			preventDuplicates: true,
		}),

		ModalModule.forRoot(),
		NgIdleKeepaliveModule.forRoot(),

		CoreModule,
		SharedModule
	],
	providers: [
		AppInsightsService
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
